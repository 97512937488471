import FlexboxReact from "flexbox-react";
import {WilmaPlusCard} from "../elements/Card";
import ContactOutlet from "../elements/ContactSource";

function ContactUsPage() {

    return <>
        <FlexboxReact alignItems={'center'} flexDirection={'column'} style={{margin: '2rem'}}>
            <h1>Yhteystiedot</h1>

            <WilmaPlusCard id={'contactus-box'} style={{}} cardStyle={{flex: '1 1'}}>
                <FlexboxReact flexDirection={'column'} style={{rowGap: '1rem'}}>
                    <ContactOutlet type={'email'} name={'Sähköposti yleisistä asioista'} url={'mailto:'+(global as any).config.infoEmail}/>
                    <ContactOutlet type={'help'} name={'Tukisähköposti'} url={'mailto:'+(global as any).config.supportEmail}/>
                    <ContactOutlet type={'twitter'} name={'Twitter: @wilmaplus'} url={(global as any).config.twitterLink}/>
                    <ContactOutlet type={'insta'} name={'Instagram: @wilmaplus'} url={(global as any).config.instaLink}/>
                    <ContactOutlet type={'github'} name={'Github: @wilmaplus'} url={(global as any).config.githubLink}/>
                    <ContactOutlet type={'helpportal'} name={'Ohje- ja tukiportaali'} url={(global as any).config.helpPortal}/>
                </FlexboxReact>
            </WilmaPlusCard>
        </FlexboxReact>
    </>
}

export default ContactUsPage;