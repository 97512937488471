import FlexboxReact from "flexbox-react";
import {NavLink} from "react-router-dom";
import {WilmaPlusButton} from "../elements/Button";

function NotFoundPage() {

    return <>
        <FlexboxReact alignItems={'center'} justifyContent={'center'} alignContent={'center'} flexDirection={'column'} style={{margin: '2rem'}}>
            <h1>Hups, olet eksynyt synkille poluille</h1>
            <p style={{marginBottom: '2rem'}}>Kirjoittamasi URL-osoite on virheellinen tai sitä ei ole olemassa.</p>
            <NavLink to={'/'}><WilmaPlusButton>Palaa etusivulle</WilmaPlusButton></NavLink>
        </FlexboxReact>
    </>
}

export default NotFoundPage;