import React, {useState} from "react";
import {WilmaPlusCard} from "../elements/Card";
import FlexboxReact from "flexbox-react";
import LinkContactOutlet from "../elements/LinkContactOutlet";

const PrivacyPolicyItem = (props: {name: string, text: any}) => {
    return <WilmaPlusCard id={'faqbox'} style={{}} cardStyle={{flex: '1 1', width: '100%'}}>
        <h3><b>{props.name}</b></h3>
        <p style={{marginBottom: '0'}}>{props.text}</p>
    </WilmaPlusCard>
}

function PrivacyPolicyPage() {
    /*const [gdprContent] = useState([
        [
            "1. Henkilötietojen käsittelyn tarkoitus ja peruste sekä tietoryhmät",
            <>
                <span>Käsittelemme, keräämme ja säilytämme käyttäjien henkilötietoja ennalta määriteltyihin laillisiin tarkoituksiin. Pääasiassa saamme henkilötiedot käyttäjiltä itseltään lomakkeilta, sähköpostitse tai muulla vastaavalla tavalla. Lisäksi saamme tietoja konelukemalla niitä Wilman rajapinnasta. Tietoja ei käytetä automatisoituun päätöksentekoon tai profilointiin. Varmistamme, että käsittely perustuu aina lakiin. Henkilötietojen käsittelyn tarkoitukset, tietoryhmät ja sovellettavat käsittelyperusteet ovat esitetty yksityiskohtaisemmin jäljempänä.</span>
                <br/>
                <br/>
                <span>Tietojen antaminen meille on pääsääntöisesti vapaaehtoista. Emme käsittele käyttäjän henkilötietoja, ellei käyttäjä ole antanut nimenomaista suostumustaan henkilötietojen käsittelylle lisäominaisuuksien saamiseksi. Käyttäjän laitteelle asennettava Wilma Plus -sovellus tekee oletuksena suoria pyyntöjä Wilman rajapintaan, joten emme voi käsitellä tällaista tietoa. <u>Ainoastaan lisäominaisuuksien toteuttamisessa toimimme rekisterinpitäjän roolissa.</u> Tällöin käsittelemme vain sellaisia henkilötietoja, jotka ovat välttämättömiä ja tarpeellisia toiminnallisuuden varmistamiseksi. Olemme Visman kanssa yhteistyökumppaneita ja olemme hyväksyneet niiden kanssa rajapintasopimuksen.</span>
                <br/>
                <br/>
                <span><strong>Alle 13-vuotiaat eivät saa käyttää sovellusta. Mikäli tätä ehtoa ei noudateta ja käyttäjä on ottanut käyttöön lisätoimintoja, pyydämme olemaan yhteydessä meihin. Poistamme tiedot välittömästi.</strong></span>
                <br/>
                <br/>
                <span>Jokainen käyttäjä määrittelee itse, antaako vai peruuttaako suostumuksen käyttötarkoituksen mukaiselle henkilötietojen käsittelylle. Alla esitetyt toiminnot perustuvat kaikki suostumukseen.</span>
                <br/>
                <br/>
                <LinkContactOutlet name={'Ilmoitusjärjestelmän lähdekoodi on avointa'} type={'github'} url={'https://github.com/wilmaplus/notifier'}/>
                <br/>
                <table className={'privacypolicy-table'}>
                    <thead>
                    <th>Käyttötarkoitus</th>
                    <th>Käsittelyn asiayhteys</th>
                    <th>Henkilötietojen ryhmät</th>
                    <th>Säilytysaika</th>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Käyttäjän kirjautuminen sovellukseen</td>
                        <td>Tunnistautuminen Wilman rajapintaan edellyttää tunnisteen luomisvaihetta, jossa Wilma Plus -palvelin luo kirjautumiselle tunnisteen. Wilma Plussan ja Visman väliset sopimusehdot määräävät tunnistautumisprosessin tapahtuvan palvelimella.
                            <br/>
                            <br/>
                            Varsinainen kirjautumisprosessi tapahtuu käyttäjän omalla laitteella, joka on suoraan yhteydessä Wilman palvelimeen.
                            <br/>
                            <br/>
                            Käyttäjä voi vaihtoehtoisesti käyttää OpenID-kirjautumistapaa, jolloin emme käsittele näitä tietoja. Kaikki oppilaitokset eivät tarjoa tätä kirjautumistapaa.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjänimi</li>
                            </ul>
                        </td>
                        <td>Käyttäjänimi tuhoutuu välittömästi palvelimelta prosessin jälkeen. Tämä perustuu meidän ja Visman väliseen rajapintasopimukseen.</td>
                    </tr>
                    <tr>
                        <td>Wilma Plus tunnistautuminen</td>
                        <td>
                            Tämän ominaisuuden käyttöönotto luo käyttäjälle Wilma Plus -tunnuksen. Kyseessä on tunnistuksenvälityspalvelu, jolla käyttäjän on mahdollista tunnistautua kolmannen osapuolen palveluihin käyttäen omaa Wilma Plus -tunnusta. Ominaisuuden käyttö perustuu luottamusverkostoon, joiden jäsenten kanssa on laadittu asianmukaiset sopimukset.
                            <br/>
                            <br/>
                            Tunnistautumisprosessissa käyttäjä tutustuu, mihin kolmannen osapuolen palveluun on kirjautumassa ja mitä tietoja palvelulle luovutetaan, ennen kuin käyttäjä valtuuttaa suostumuksellaan tunnistautumisen.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjän koko nimi</li>
                                <li>Käyttäjän oppilaitoksen nimi</li>
                                <li>Käyttäjän uniikki tunniste</li>
                                <li>Käyttäjän Wilma-instanssin URL-osoite</li>
                                <li>Käyttäjän profiilikuva</li>
                                <li>Käyttäjän sähköpostiosoite</li>
                            </ul>
                        </td>
                        <td>
                            Tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>tiedot poistetaan automaattisesti, jos viimeisestä tunnistautumisesta ominaisuuden välitykseksellä on kulunut kaksi vuotta.</li>
                            </ul>
                        </td>
                        </tr>
                    <tr>
                        <td>Koe-, tuntimerkintä- ja tiedoteilmoitukset</td>
                        <td>
                            Ilmoitusjärjestelmä mahdollistaa ilmoitukset uusista koetuloksista, tuntimerkinnöistä ja tiedotteista. Tietoja käytetään push-ilmoituksen lähettämiseen.
                            <br/>
                            <br/>
                            Tiedot on tallennettu salatussa muodossa palvelimelle, eikä rekisterinpitäjä voi lukea niitä. Ohjelma kuitenkin joutuu purkamaan tiedot, kun ilmoitus lähetetään laitteelle. Tällöin puretut tiedot tuhoutuvat välittömästi prosessin jälkeen palvelimen muistista. Yhteys laitteen ja palvelimen välillä on kuitenkin salattu.
                        </td>
                        <td>
                            <ul>
                                <li>Kokeiden tiedot</li>
                                <li>Tuntimerkinnät</li>
                                <li>Tiedotteet</li>
                            </ul>
                        </td>
                        <td>
                            Salatussa muodossa olevia tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>kun viimeisestä push-ilmoituksen pyynnöstä on kulunut enintään vuosi.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Ruokalistan arvostelut</td>
                        <td>
                            Ruokalistan arvosteluilla käyttäjän on mahdollista arvioida kouluruokalansa aterioita ja jakaa mielipiteensä muille.
                            <br/>
                            <br/>
                            Käyttäjän nimi on näkyvissä muille ruokalistan käyttäjille.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjän koko nimi</li>
                                <li>Käyttäjän uniikki tunniste</li>
                                <li>Ruoka-arvostelu</li>
                            </ul>
                        </td>
                        <td>
                            Tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>arvostelu poistetaan, kun sen lisäämisestä on kulunut kaksi vuotta.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Omat kotitehtävät</td>
                        <td>
                            Käyttäjän on mahdollista opettajien antamien kotitehtävien lisäksi lisätä omia kotitehtäviä. Käyttäjä voi halutessaan omalla suostumuksellaan jakaa omat kotitehtävämerkinnät omien ryhmäläisten kanssa.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjän koko nimi</li>
                                <li>Käyttäjän uniikki tunniste</li>
                                <li>Käyttäjän valitseman Wilma-kurssin uniikki tunniste</li>
                                <li>Käyttäjän käyttäjätyyppi</li>
                            </ul>
                        </td>
                        <td>
                            Tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>kotitehtävä poistetaan automaattisesti, kun sen lisäämisestä on kulunut kolme kuukautta.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Tiedostoliitteet</td>
                        <td>
                            Tässä ominaisuudessa käyttäjän on mahdollista lähettää Wilma-viestien kanssa tiedostoliitteitä. Liitetiedosto tallennetaan palvelimelle viestin vastaanottajaa varten.
                            <br/>
                            <br/>
                            Liitteellä on uniikki tunniste, joka ei ole arvattavissa. Ainoastaan linkin saaneilla on mahdollisuus nähdä liite salatun https-yhteyden välityksellä.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjän koko nimi</li>
                                <li>Käyttäjän oppilaitoksen nimi</li>
                                <li>Käyttäjän uniikki tunniste</li>
                                <li>Käyttäjän Wilma-instanssin URL-osoite</li>
                                <li>Käyttäjän lähettämä tiedosto</li>
                            </ul>
                        </td>
                        <td>
                            Tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>liite poistetaan automaattisesti, jos sen lähettämisestä on kulunut kolme kuukautta.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Palautetoiminto</td>
                        <td>
                            Palautteella käyttäjä voi lähettää kehitystiimille palautetta tai ongelmaraporttia sovelluksesta. Tietoja käytetään käyttäjän palautteen tukena esimerkiksi ongelmaraporttien ratkaisemiseen. Mikäli havaitsemme roskapostia palautetoiminnon välityksellä, voimme estää palautetoiminnon toiminnallisuuden.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjän koko nimi</li>
                                <li>Käyttäjän oppilaitoksen nimi</li>
                                <li>Käyttäjän uniikki tunniste</li>
                                <li>Käyttäjän tunnuksen tyyppi</li>
                                <li>Käyttäjän Wilma-instanssin URL-osoite</li>
                                <li>Käyttäjän IP-osoite</li>
                            </ul>
                        </td>
                        <td>
                            Tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>niin kauan kuin palautetiketti on auki. Sulkeutumisen jälkeen palautetikettiä säilytetään kolme kuukautta.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>Viestin salaus</td>
                        <td>
                            Viestin salauksella käyttäjä ja hänen vastaanottajat voivat keskustella salatusti Wilma-viestien avulla. Wilma Plussan palvelimelle tallentuu käyttäjän laitteella luotu julkinen salausavain. Viestin salauksen purkaminen tapahtuu aina päätelaitteilla.
                            <br/>
                            <br/>
                            Virallisessa Wilmassa viestin sisältö näkyy salattuna. Lisätietoa salauksen toiminnasta saa olemalla yhteydessä meihin.
                        </td>
                        <td>
                            <ul>
                                <li>Käyttäjän uniikki tunniste</li>
                                <li>Käyttäjän Wilma käyttäjätyyppi</li>
                                <li>Käyttäjän Wilma-instanssin URL-osoite</li>
                            </ul>
                        </td>
                        <td>
                            Tietoja säilytetään
                            <ul>
                                <li>niin kauan kunnes ominaisuus otetaan pois päältä, jolloin tiedot poistuvat; tai</li>
                                <li>niin kauan kun käyttäjä käyttää sovellusta aktiivisesti, jonka jälkeen automaattisesti poistetaan kolmen kuukauden kuluttua.</li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>Asiakirjassa nimellä "Wilma" tarkoitetaan Visma Enterprise Oy:n kehittämää <a href={"https://wilma.fi"}>Wilma-järjestelmää.</a></p>
            </>
        ],
        [
            "2. Miten pidämme henkilötietosi turvassa?",
            <>
                Käsittelemme henkilötietoja tavalla, jolla pyritään varmistamaan henkilötietojen asianmukainen turvallisuus, mukaan lukien suojaaminen luvattomalta käsittelyltä sekä vahingossa tapahtuvalta häviämiseltä, tuhoutumiselta tai vahingoittumiselta.
                <br/>
                <br/>
                Käytämme asianmukaisia teknisiä ja organisatorisia suojatoimia tämän tavoitteen varmistamiseksi. Käytämme muun muassa palomuuria, salaustekniikoita ja turvallisia laitetiloja. Olemme varmistaneet, että käytössä olevan palvelimen palveluntarjoaja noudattaa tietosuojalainsäädäntöä. Palvelin sijaitsee Saksassa. Emme tallenna mitään lokitietoja käyttäjistään. Henkilötietoja ei missään tilanteessa siirretä EU:n ja Euroopan talousalueen ulkopuolelle.
            </>
        ],
        [
            "3. Henkilötietoja koskevat oikeutesi",
            <>
                Voit hyödyntää edellä kuvattuja oikeuksiasi käyttämällä verkossa sijaitsevaa verkkopalveluamme <a href={"https://gdpr.wilmaplus.fi"}>gdpr.wilmaplus.fi</a> tai olemalla yhteydessä alempana mainittuun yhteyshenkilöön.
                <br/>
                <br/>
                Rekisteröidyillä on seuraavia oikeuksia omien tietojensa käsittelyyn liittyen:
                <ul>
                    <li>oikeus tutustua tietoihin</li>
                    <li>oikeus oikaista tiedot</li>
                    <li>oikeus poistaa tiedot ja tulla unohdetuksi</li>
                    <li>oikeus rajoittaa tietojen käsittelyä</li>
                    <li>oikeus siirtää tiedot järjestelmästä toiseen</li>
                    <li>oikeus vastustaa tietojen käsittelyä</li>
                    <li>oikeus tehdä valitus valvontaviranomaiselle</li>
                </ul>
            </>
        ],
        ["4. Rekisteristä vastaava yhteyshenkilö",
            <>
                <b>{(global as any).config.privacy.responsibleName}</b>
                <br/>
                <LinkContactOutlet name={(global as any).config.privacy.responsibleMail} type={'mail'} url={'mailto:'+(global as any).config.privacy.responsibleMail}/>
                <LinkContactOutlet name={(global as any).config.privacy.responsibleTel} type={'phone'} url={'tel:'+(global as any).config.privacy.responsibleTel.replaceAll(" ", "")}/>
            </>]
    ])*/
    const [gdprContent] = useState([]);
    const [otherInfo] = useState([
        [
            "Sovelluksen käyttöoikeudet",
            <>
                <p>
                    Wilsu Plus pyytää joidenkin ominaisuuksien kohdalla erityisiä käyttö-oikeuksia.<br/>
                    Alla olevassa listassa on listattu käyttöoikeus ja mihin sitä käytetään:
                </p>
                <ul>
                    <li><strong>Kamera</strong>: Käytetään QR-Koodin skannaamiseen ja kotitehtävien lisäyksessä</li>
                    <li><strong>Kalenteri:</strong> Wilsu Plus pystyy lisäämään työjärjestyksiä ja kokeita kalenteriin,
                        johon vaaditaan tämä oikeus.
                    </li>
                    <li><strong>Sijainti: </strong>Tarvitaan "Tuo toisesta puhelimesta" ominaisuutta varten.
                        Käyttöoikeutta tarvitaan vain siirron aikana. Kun siirto on valmis, voit evätä sen pois.
                    </li>
                    <li><strong>Tallennustila: </strong>Tallennustilaa tarvitaan liitteiden lataamiseen ulkoiseen
                        muistiin ja/tai kuvien valitsemiseen galleriasta
                    </li>
                    <li><strong>Yhteystiedot</strong>: Tämän käyttöoikeuden vaatii itse Android järjestelmä sen
                        "tilihallintaan" eli "<code className="code codeInline"
                                                    spellCheck="false">AuthenticatorService</code>" varten, eli Wilma
                        Plus ei käytä sitä. Wilsu Plus ei ikinä pyydä tätä käyttöoikeutta hyväksyttäväksi, joten
                        sovellus toimii normaalisti ilman tämän hyväksymistä.
                    </li>
                </ul>
            </>
        ],
        [
            "\"Tuo toisesta puhelimesta\" ominaisuus",
            <>
                Tämä ominaisuus siirtää kaikki laitteellasi oleva tieto toiseen laitteeseen langattomasti.<br/>
                Siirto ei käytä palvelimia, yhteys on laitteesta laitteeseen, ja kommunikaatio on salattu.
            </>
        ],
        [
            "Laitteella tallentuvan tiedon turvallisuus",
            <>
                Käyttäjän omalla laitteella tallentuva tieto Wilsu Plus -sovelluksessa on oletuksena turvallisesti tallennettu pois muiden sovelluksien ulottuvilta. Pyrimme aina parantamaan turvallisuutta sovelluksessa.
                <br/>
                Jos laitteesi on:
                <ul>
                    <li>Root</li>
                    <li>Bootloader Unlock</li>
                    <li>Tai muulla keinolla ja/tai tavalla muokattu, joka heikentää tietojen turvallisuutta</li>
                </ul>
                <br/>
                Käyttäjä on itse vastuussa tallennetun datan turvallisuudesta, sillä laitteen turvallisuusominaisuudet ovat ohitettavissa yllä mainitsemissa tiloissa.
                <br/>
                Wilsu Plus -sovelluksen kehittäjä ei vastaa yllä mainittujen tavoilla muokatun järjestelmän kautta varastetut ja/tai vuodetuista tiedoista.
                <br/><br/><br/>
                Jos laitteesi ei ole yllä mainitseman listan avulla muokattu, Wilsu Plus kehittäjä on vastuussa laitteelle tallennetun tiedon turvallisuudessa. Jos uskot, että olet löytänyt vian tai tietoturva-aukon, ilmoitathan meille siitä rekisterinpitäjälle mahdollisimman pian.
            </>
        ],
        [
            "Tietosuojakäytännön voimassaolo",
            <>
                <p>Wilsu Plus:lla ja sen kehittäjillä on täydet valtuudet muokata tietosuojakäytäntöä.</p>
                <p>Ilmoitamme käyttäjille sivuston ja sovelluksemme kautta, jos tietosuojakäytäntöön on tulossa suuria muutoksia.</p>
                <p>Pienistä muutoksista emme välttämättä ilmoita, jos koemme sen olevan tarpeeton.</p>
                <p>Suosittelemme tietosuojakäytännön lukua säännöllisesti.</p>
            </>
        ]
    ]);
    return <>
        <FlexboxReact alignItems={'center'} flexDirection={'column'} style={{margin: '2rem'}}>
            <h1 style={{width: '100%', maxWidth: '950px', marginBottom: '0'}}>Tietosuojakäytäntö</h1>
            <p style={{width: '100%', maxWidth: '950px', marginBottom: '0'}}>Tietosuoja on perusoikeus, joka turvaa rekisteröidyn oikeuksien ja vapauksien toteutumisen henkilötietojen käsittelyssä. Tietosuojan tarkoituksena on osoittaa, milloin ja millä edellytyksillä henkilötietoja voidaan käsitellä. Henkilötiedoilla tarkoitetaan kaikkia tietoja, jotka liittyvät tunnistettuun tai tunnistettavissa olevaan luonnolliseen henkilöön. Tässä tietosuojakäytännössä informoimme Wilsu Plus -sovelluksen henkilötietojen käsittelystä EU:n yleisen tietosuoja-asetuksen (2016/679) mukaisesti. </p>
            <p style={{width: '100%', maxWidth: '950px'}}>Viimeisin muutos 1. lokakuuta 2022.</p>
            {gdprContent.map(i => {
                return <PrivacyPolicyItem name={i[0] as string} text={i[1]}/>
            })}
            <PrivacyPolicyItem name={"Huomautus"} text={"Keskeytimme henkilötietojen käsittelyn 30.9.2022, koska se ei ollut enää käyttötarkoitussidonnaisuuden mukaista. Kaikkien lisätoimintoja käyttävien käyttäjien tiedot on lopullisesti tuhottu. Emme toistaiseksi toimi rekisterinpitäjän roolissa.\n" +
                "\n" +
                "Olemme suunnittelemassa lisätoiminnoille uutta toteutustapaa, josta tiedotetaan pian. "}/>
            <hr style={{width: '100%', maxWidth: '950px'}}/>
            <h1 style={{width: '100%', maxWidth: '950px'}}>Muuta tietoa</h1>
            {otherInfo.map(i => {
                return <PrivacyPolicyItem name={i[0] as string} text={i[1]}/>
            })}
        </FlexboxReact>
    </>
}

export default PrivacyPolicyPage;