import React, {useState} from "react";
import './HomeShowcase.scss';
import FlexboxReact from "flexbox-react";

const Feature = ({name}: {name: string}) => {
    return <tr>
        <td><h4>{name}</h4></td>
        <td><img className={'comparison-mark'} src={process.env.PUBLIC_URL+'/checkmark.svg'} alt={'Saatavilla'}/></td>
        <td><img className={'comparison-mark'} src={process.env.PUBLIC_URL+'/xmark.svg'} alt={'Ei saatavilla'}/></td>
    </tr>
}

function HomeShowcase() {
    const [features] = useState([
        "Tiedostoliitteet viestinnässä",
        "Omien kotitehtävien lisääminen *",
        "Viestien arkistointi ja luonnokset",
        "Ruokalista",
        "Kustomointi",
        "Widgetit aloitusnäytöllä",
        "Kurssitarjotin *",
        "Opettajat ja luokkahuoneet",
        "Viestien salaus *"
    ]);
    return <div>
        <div className={'home-showcase-container'}>
            <FlexboxReact alignItems={'center'}  style={{height: '100%'}} flexDirection={'column'}>
                <h1>
                    You can't imagine how many pluses you will find
                </h1>
                <h3>
                    Täydellisin Wilma sovellus ikinä
                </h3>
                <FlexboxReact justifyContent={'flex-start'} flexDirection={'row'}>
                    <img src={process.env.PUBLIC_URL+'/phone1.png'} className={'showcase-phone phone-android'} alt={"Android"}/>
                    <img src={process.env.PUBLIC_URL+'/phone2.png'} className={'showcase-phone phone-iphone'} alt={"iOS"}/>
                </FlexboxReact>
            </FlexboxReact>
        </div>
        <div className={'feature-section'}>
            <div className={'feature-section-content'} >
                <h2>Tätä Wilman olisi pitänyt olla alusta alkaen</h2>
                <p>Uskomattoman yksinkertainen, houkutteleva ja sujuva käyttökokemus sekä monipuoliset ominaisuudet tuovat ennennäkemättömän käyttökokemuksen Wilman parissa. Sano ei hämmentäville napeille ja hitaalle Wilmalle, vaihda Wilsu Plus -sovellukseen.</p>

                <table id={'wilmaplus-feature-comparison'}>
                    <tr>
                        <td></td>
                        <td><h4>Wilsu Plus</h4></td>
                        <td><h4>Wilma</h4></td>
                    </tr>
                    {features.map(item => {return <Feature name={item}/>})}
                </table>
                <span style={{fontSize: '0.8em'}}>Listattuna on suosituimmat Wilsu Plus ominaisuudet, kaikkia ominaisuuksia ei ole tässä listassa.</span><br/>
                <span style={{fontSize: '0.5em'}}>* Saatavilla toistaiseksi vain Android-sovelluksessa</span>
            </div>
        </div>
        <div className={'feature-middle-section'}>
            <div className={'feature-section-content'}>
                <h2>Yksinkertainen ja helppokäyttöinen</h2>
                <p>Tavoitteemme ja visiomme on tehdä Wilmasta yhtä helppoa kuin Whatsappin tai minkä tahansa muun viestintäsovelluksen käyttäminen.</p>
                <br/>
                <div className={'showcase-grid-row'}>
                    <div className={'showcase-grid-col'}>
                        <img src={process.env.PUBLIC_URL+'/ope.png.jpg'} alt={"Feature Requests"}/>
                    </div>
                    <div className={'showcase-grid-col hidden-mobile'}>
                        <img src={process.env.PUBLIC_URL+'/msg.png.jpg'} alt={"Feature Requests"}/>
                        <img src={process.env.PUBLIC_URL+'/msgactions.png.jpg'} alt={"Feature Requests"}/>
                        <img src={process.env.PUBLIC_URL+'/msgdraft.png.jpg'} alt={"Feature Requests"}/>
                        <img src={process.env.PUBLIC_URL+'/print.jpg'} alt={"Feature Requests"}/>
                        <img src={process.env.PUBLIC_URL+'/jaksot.jpg'} alt={"Feature Requests"}/>
                    </div>
                    <div className={'showcase-grid-col'}>
                        <img src={process.env.PUBLIC_URL+'/obs.jpg'} alt={"Feature Requests"}/>
                        <img src={process.env.PUBLIC_URL+'/students.jpg'} alt={"Feature Requests"}/>
                        <img src={process.env.PUBLIC_URL+'/grades.png.jpg'} alt={"Feature Requests"}/>
                    </div>
                </div>
            </div>
        </div>
        <div className={'feature-middle-section'}>
            <div className={'feature-section-content'}>
                <h2>Me kuuntelemme sinua</h2>
                <p>Käyttäjien palaute on ratkaisevan tärkeää, jotta voimme tehdä sovelluksestamme entistä paremman. Palautteen sovelluksesta voi lähettää suoraan Wilsu Plussassa, sovelluskaupassa tai sähköpostilla.</p>
                <br/>
                <img src={process.env.PUBLIC_URL+'/featreq.png.jpg'} className={'small-image'} alt={"Feature Requests"}/>
                <br/>
            </div>
        </div>
        <div className={'feature-end-section'}>
            <div className={'feature-section-boundary feature-section-center'}>
                <h2>Älä odota, lataa nyt</h2>
                <p>Helpota asiointi koulun kanssa lataamalla Wilsu Plus sovellus</p>
                <a href="https://play.google.com/store/apps/details?id=com.developerfromjokela.wilmaplus&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel={'noreferrer'}><img alt="Saatavilla Play Kaupasta" src="https://play.google.com/intl/en_us/badges/images/generic/fi_badge_web_generic.png" height="70px"/></a>
                <a href="https://apps.apple.com/app/wilma-plus/id1586203279" target="_blank" rel={'noreferrer'}><img alt="Lataa App Storesta" src={process.env.PUBLIC_URL+'/appstore_dark.svg'} height="45px"/></a>
                <a href="/dl/wilsuplus.apk">Lataa APK-tiedosto</a>
            </div>
        </div>
    </div>
}

export default HomeShowcase;
