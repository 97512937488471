import React, {useState} from "react";
import {WilmaPlusCard} from "../elements/Card";
import FlexboxReact from "flexbox-react";

const FAQItem = (props: {name: string, text: string}) => {
    return <WilmaPlusCard id={'faqbox'} style={{}} cardStyle={{flex: '1 1', width: '100%'}}>
        <h3>{props.name}</h3>
        {props.text.split('\n').map(i => {
            return <p style={{marginBottom: '0'}}>{i}</p>
        })}
    </WilmaPlusCard>
}

function FAQPage() {
    const [faqContent] = useState([
        ["Onko tämä virallinen Wilma Sovellus?", "Wilsu Plus on epävirallinen Wilma sovellus, joka sisältää suuri määrä ominaisuuksia ja elämää helpottavia korjauksia, joita virallisessa Wilmassa ei ole."],
        ["Toimiiko tämä sovellus?", "Wilsu Plussan Android-versiolle on julkaistu uusi päivitys, joka korjaa Wilman ydintoiminnot. Kaikki toiminnot ja lisäominaisuudet eivät ole vielä saatavilla, sillä olemme tekemässä niihin korjaustöitä.\niOS versio toimii tällä hetkellä vain OpenID tilien kanssa. iOSiin on tulossa päivitys, joka korjaa toiminnallisuuden myös normaaleille käyttäjille."],
        ["Minulle tulee tietokoneella päällekkäinen kirjautuminen virheitä, mistä johtuu?", "Tämä johtuu Wilman epämukavasta ominaisuudesta, joka rajoittaa aktiivista kirjautumista vain yhteen laitteeseen kerrallaan. Wilsu Plus on älykäs ja kirjaa sinut takaisin sisään jos sinut heitettiin ulos tämän takia, mutta Wilman selainkäyttöliittymä ei ja näyttää sen sijaan virheen. Tässä asiassa on täysin Visman tehtävä korjata tämä ongelma."],
        ["Voinko opettajana tai koulun henkilökuntana käyttää tätä sovellusta?", "Ehdottomasti voit, kunhan varmistat oppilaitoksen ATK-henkilökunnalta luvan käyttämiseen."],
        ["Kuinka luotettava tämä sovellus on?", "Wilsu Plus hakee tietojasi suoraan Wilman verkkosivulta, jotka näet heti mobiilisovelluksessa. Lähtökohtaisesti kaikkia hakemiasi tietoja käsitellään paikallisesti laitteellasi eikä niitä tallenneta jatkokäsittelyä varten. Puolestaan lisätoiminnot edellyttävät jonkin verran henkilötietojen käsittelyä palvelimella, jolloin käsittelemme luvallasi vain lisätoiminnon tarjoamiseen ja tietoturvan ylläpitämiseen välttämättömiä tietoja, kuten käyttäjätunnuksesi uniikkia tunnistetta. \n" +
        "\n" +
        "Toistaiseksi lisätoiminnot eivät toimi, mutta toivottavasti saamme ne pian takaisin toimintaan. Olemme suunnitelleet mobiilisovelluksen oletusarvoisen tietosuojan ja tietoturvan periaatteita noudattaen, jotta käyttäjiemme tiedot olisivat turvassa. Lisäksi osa lähdekoodista on avointa lähdekoodia, johon voit tutustua OpenWilma-projektin sivuilla. Wilsu Plussalla on yli 6000 päivittäistä käyttäjää."],
        ["Kuntani/Kaupunkini sanoo ettei saa käyttää muita sovelluksia, mitä teen?", "Wilma on tarkoitettu ensisijaisesti koulun ja kodin väliseksi yhteydenpitovälineeksi. Tällä hetkellä puhelimesi sovelluskaupoissa tarjotaan virallista Wilmaa sekä epävirallista Wilsu Plussaa. Myös sinulla on henkilökohtainen Wilma-tunnus. Lähtökohtaisesti ihminen voi itse päättää, minkä mobiilisovelluksen haluaa ladata henkilökohtaiselle puhelimelle mainittua käyttötarkoitusta varten. Kukaan ei voi puuttua siihen, mitä lataat puhelimellesi, ja miten käytät Wilma-tunnusta. Kyse on pitkälti henkilötietojen suojasta, josta vastaa aina rekisterinpitäjä. Noudatamme tietosuojalainsäädäntöä kuten Visma ja opetuksen järjestäjät, ellei heitä paremmin. \n" +
        "Meistä on uutisoitu syyskuussa Suomen valtamediassa. Meistä on myös tehty runsaasti ilmoituksia tietosuojavaltuutetulle. Useat väitteet perustuvat siihen, että olemme opetuksen järjestäjille tietosuojariski: opetuksen järjestäjät eivät voi varmistaa mobiilisovelluksen riittävän tietosuojan tasoa. Lisäksi eräs toinen klassinen väite on, ettei henkilötietojen käsittely ole lainmukaista. Toistaiseksi näihin väitteisiin ei ole esitetty muita varteenotettavia tai voimassa olevaan oikeuteen perustuvia perusteluita. Siitä huolimatta olemme laatineet vaikutustenarvioinnin, jossa tutkimme väitteitä ja arvioimme henkilötietojen käsittelyä.\n" +
        "Olemme pyytäneet tietosuojavaltuutetun toimistosta julkisuuslain mukaisella tietopyynnöllä kaikki asiakirjat, jotka liittyvät vallitsevaan tapaukseen. Ilmoituksia tehneillä tietosuojavastaavilla ja niiden organisaatioilla on selkeästi toisistaan eriäviä näkemyksiä ja perusteluita mobiilisovelluksen henkilötietojen käsittelystä, kuten ilmeisesti on meilläkin. Yleisesti ottaen joitakin ilmoituksia on tehty avaamatta ja tutustumatta mobiilisovellukseen ja sen toimintaan. Hyväksymme pienen mahdollisuuden, että voimme olla väärässä, mutta henkilötietojen käsittelyn laillisuudesta päättää lopuksi tietosuojavaltuutettu. Osallistumme mielellämme etukäteen rakentavaan dialogiin, mikäli haluat tutustua vaikutustenarviointiin.\n" +
        "Emme odota tietosuojavastaavilta erityisen syvää ymmärrystä tietoturvasta ja mobiilisovelluksen toiminnasta, mutta olemme jossain määrin pettyneitä heikkoon valmisteluun: kukaan ei yrittänyt kysymällä selvittää miten mobiilisovellus toimii. Pidämme tärkeänä koulujen ja tietosuojavastaavien ohjeiden noudattamista. Toisaalta kukaan ei voi rangaista epävirallisen mobiilisovelluksen käytöstä. Suosittelemme punnitsemaan itsenäisesti Wilsu Plussan edut ja haitat. Meidän tarkoituksena on tarjota vain laadukasta ja maksutonta mobiilisovellusta Wilman selaamiseen. \n"],
    ])
    return <>
        <FlexboxReact alignItems={'center'} flexDirection={'column'} style={{margin: '2rem'}}>
            <h1>Usein kysyttyjä kysymyksiä</h1>
            {faqContent.map(i => {
                return <FAQItem name={i[0]} text={i[1]}/>
            })}
        </FlexboxReact>
    </>
}

export default FAQPage;