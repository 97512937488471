import './Button.scss';
import React from 'react';

export const WilmaPlusButton = (props: any) => {
    return <button className="wilmaplus-button" onClick={props.onClick} style={props.style}>
        {props.children}
    </button>
}

export const WilmaPlusLink = (props: any) => {
    return <a className="wilmaplus-button" href={props.href} style={props.style} target={'_blank'}>
        {props.children}
    </a>
}

