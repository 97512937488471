import React from "react";
import FlexboxReact from "flexbox-react";
import './ContactSource.css'

const ContactOutlet = (props: {type: string, name: string, url: string}) => {
    return <a href={props.url} target={'_blank'} rel={'noreferrer'}>
        <FlexboxReact flexDirection={'row'} alignItems={'center'} style={{'column-gap': '0.5rem'}}>
            <img className={'inverted-icon contact-source-icon'} src={process.env.PUBLIC_URL+props.type+'.svg'} alt={props.type}/>
            <h3>{props.name}</h3>
        </FlexboxReact>
    </a>
}

export default ContactOutlet