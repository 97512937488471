import PropTypes from 'prop-types';
import React from 'react';
import './Card.css'

export const WilmaPlusCard = (props: {id: string|undefined, style: object, cardStyle: object, children: any}) => {

    return <div className="wilmaplus-card" id={props.id} style={props.cardStyle}>
        <div className={'card-content'} style={props.style}>
            {props.children}
        </div>
    </div>
}

WilmaPlusCard.propTypes = {
    style: PropTypes.object,
    cardStyle: PropTypes.object
}