import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

(global as any).config = {
    instaLink: 'https://instagram.com/wilmaplus/',
    githubLink: 'https://github.com/wilmaplus/',
    twitterLink: 'https://twitter.com/wilmaplus/',
    helpPortal: 'https://wilsuplus.fi/help/',
    supportEmail: 'tuki@wilmaplus.fi',
    infoEmail: 'info@wilmaplus.fi',
    donateLink: 'https://paypal.me/developerfromjokela',
    privacy: {
        holderName: 'Wilsu Plus ja kehittäjät',
        responsibleName: 'Ruben Mkrtumyan',
        responsibleMail: 'ruben@wilmaplus.fi',
        responsibleTel: '+358 40 364 2686',
    },
    banners: [
        {
            enabled: true,
            type: 'warning',
            content: "Ongelmia korjaava päivitys on julkaistu Applen alustoille",
            readMoreLink: "https://wilsuplus.fi/help/discussion/30/korjaus-wilsu-plussaan-applen-laitteille"
        }
    ]
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
