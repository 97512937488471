import React from "react";

import './WilmaPlusBottomBar.scss'
import {NavLink} from "react-router-dom";

function WilmaPlusBottomBar() {
    return <footer className={'wilmaplus-bottombar-container'}>
        <div className={'wilmaplus-bottombar'}>
            <div className={'bottombar-row'}>
                <div className={'bottombar-col'}>
                    <h2 className={'toolbar-name'}><b>Wilsu Plus</b></h2>
                    <span>Tehty  ❤️  <a href={"https://goo.gl/maps/f5DC7PSSUPbb3wre7"} target={'_blank'} rel={'noreferrer'}>Jokelassa</a></span>
                    <div className={'bottombar-social-links'}>
                        <a href={(global as any).config.instaLink} target={'_blank'} rel={'noreferrer'}><img className={'inverted-icon contact-source-icon'} src={process.env.PUBLIC_URL+'insta.svg'} alt={'Instagram'}/></a>
                        <a href={(global as any).config.twitterLink} target={'_blank'} rel={'noreferrer'}><img className={'inverted-icon contact-source-icon'} src={process.env.PUBLIC_URL+'twitter.svg'} alt={'Twitter'}/></a>
                        <a href={(global as any).config.githubLink} target={'_blank'} rel={'noreferrer'}><img className={'inverted-icon contact-source-icon'} src={process.env.PUBLIC_URL+'github.svg'} alt={'Github'}/></a>
                    </div>
                    <h4 className={'hidden-mobile'}>&copy; 2017-2022 Wilsu Plus ja kehittäjät</h4>
                </div>
                <div className={'bottombar-col'}>
                    <h3>Linkkejä</h3>
                    <br/>
                    <ul className={'bottombar-links'} style={{listStyleType: 'none'}}>
                        <li>
                            <a href={'/help'} target={'_blank'} rel={'noreferrer'}>Ohje ja tuki</a>
                        </li>
                        <li>
                            <a href={'/dl'} target={'_blank'} rel={'noreferrer'}>Lataa Wilsu Plus</a>
                        </li>
                        <li>
                            <a href={(global as any).config.donateLink} target={'_blank'} rel={'noreferrer'}>Lahjoita</a>
                        </li>
                        <li>
                            <NavLink to={'/privacy'}>Tietosuojaseloste</NavLink>
                        </li>
                        <li>
                            <a href={'https://status.wilmaplus.fi/'} target={'_blank'} rel={'noreferrer'}>Huoltokatkokset ja palvelinten tila</a>
                        </li>
                    </ul>
                </div>
            </div>
            <h4 className={'hidden-desktop'}>&copy; 2017-2022 Wilsu Plus ja kehittäjät</h4>
        </div>
    </footer>
}

export default WilmaPlusBottomBar;