import React, {useEffect, useState} from 'react';
import './WilmaPlusTopBar.css';
import FlexboxReact from "flexbox-react";
import AnimatedLink from "../elements/AnimatedLink";
import Hamburger from 'hamburger-react'
import {WilmaPlusLink} from "../elements/Button";

const Spacer = () => {
    return <div style={{flex: '1 1'}}/>
}

function WilmaPlusTopBar() {
    const [isOpen, setOpen] = useState(false)
    const [windowDimension, setWindowDimension] = useState(0);

    useEffect(() => {
        setWindowDimension((window as any).innerWidth);
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimension((window as any).innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const isMobile = windowDimension <= 640;
    return (
        <div>
            {
                (global as any).config.banners.map((banner: { enabled: any; type: string; content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; readMoreLink: any; }) => {
                    if (banner.enabled) {
                        return <FlexboxReact flexDirection={"row"} className={banner.type+'-box'} alignItems={'center'}>
                            <p style={{flex: '1'}}>{banner.content}</p>
                            {banner.readMoreLink ? <WilmaPlusLink href={banner.readMoreLink}>
                                Lue lisää
                            </WilmaPlusLink> : <></>}
                        </FlexboxReact>
                    }
                    return <></>
                })
            }
            <FlexboxReact flexDirection={"row"} className={'wilmaplus-topbar'} alignItems={'center'}>
                <img src={process.env.PUBLIC_URL+'/logo192.png'} className={'topbar-logo app-logo'} alt={"Logo"}/>
                <Spacer/>
                {isMobile ?
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                    :
                    <div className={'toolbar-links'}>
                        <AnimatedLink content={"Etusivu"} href={"/"}/>
                        <AnimatedLink content={"UKK"} href={"/faq"}/>
                        <AnimatedLink content={"Yhteystiedot"} href={"/yhteystiedot"}/>
                    </div>
                }
            </FlexboxReact>
            {isOpen ?
                <>
                    <FlexboxReact flexDirection={'column'} justifyContent={'center'} className={'mobile-toolbar'}>
                        <AnimatedLink content={"Etusivu"} href={"/"}/>
                        <AnimatedLink content={"UKK"} href={"/faq"}/>
                        <AnimatedLink content={"Yhteystiedot"} href={"/yhteystiedot"}/>
                    </FlexboxReact>
                </> :
                <></>
            }
        </div>
    );
}

export default WilmaPlusTopBar;
