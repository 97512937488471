import React from 'react';
import './App.scss';
import WilmaPlusTopBar from "./components/WilmaPlusTopBar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import WilmaPlusBottomBar from "./components/WilmaPlusBottomBar";
import FAQPage from "./pages/FAQPage";
import ContactUsPage from "./pages/ContactUs";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NotFoundPage from "./pages/NotFoundPage";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}


function App() {
  return (
      <Router>
          <ScrollToTop/>
          <div className="wilmaplus-site">
              <WilmaPlusTopBar/>
              <div className={'wilmaplus-pgcont'}>
                  <Routes>
                      <Route path={'/'} element={<HomePage />}/>
                      <Route path={'/faq'} element={<FAQPage />}/>
                      <Route path={'/yhteystiedot'} element={<ContactUsPage />}/>
                      <Route path={'/privacy'} element={<PrivacyPolicyPage />}/>
                      <Route path='*' caseSensitive={true} element={<NotFoundPage/>} />
                  </Routes>
              </div>
              <WilmaPlusBottomBar/>
          </div>
      </Router>
  );
}

export default App;
